import tsjc from '../../Images/tsjc.png'
import aya from '../../Images/aya.png'
import chani from '../../Images/chani.png'
import lisaHealth from '../../Images/lisaHealth.png'
import easyTro from '../../Images/easyTRO.png'
import positive5 from '../../Images/positive5.png'

const recentWork = [
    { 
        title: 'TSJC', 
        to: 'https://apps.apple.com/us/app/the-sugar-jar-community/id1596789097?platform=iphone',
        image: tsjc,
        type: 'React Native app'
    },
    { 
        title: 'Aya Contigo', 
        to: 'https://www.vitalaglobal.org/aya',
        image: aya,
        type: 'React PWA'
    },
    { 
        title: 'Chani', 
        to: 'https://apps.apple.com/us/app/chani/id1532791252', 
        image: chani,
        type: 'Swift app'
    },
    { 
        title: 'Lisa Health', 
        to: 'https://lisahealth.com', 
        image: lisaHealth,
        type: 'React web app'
    },
    { 
        title: 'EasyTRO', 
        to: 'https://apps.apple.com/us/app/easytro/id1480069432', 
        image: easyTro,
        type: 'React Native app'
    },
    { 
        title: 'Positive 5', 
        to: 'https://play.google.com/store/apps/details?id=com.pos5v2android', 
        image: positive5,
        type: 'React Native app'
    },
]


export default recentWork