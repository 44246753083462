import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Navbar from './Components/Navbar'

import Home from './Pages/Home/Home'

function App() {
  return (
    <div className='App'>
      <Router>
      <Navbar />

        <Switch>
          <Route path='/' exact component={Home} />
        </Switch>

      </Router>
    </div>
  );
}

export default App;
