import React from 'react'

const RecentWorkCard = (props) => {

    return (
        <a href={props.to} className='recentWorkCard' rel='noopener noreferrer' target='_blank'>
            <div className='imageDiv' style={{backgroundImage: `url(${props.image})`}}>
                <div className='titleDiv'>
                    <p className='poppins semiBold marginBottom0'>{props.type}</p>
                </div>
            </div>
        </a>
    )
}


export default RecentWorkCard