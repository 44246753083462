import React, { useState, useEffect } from 'react'

import emailjs from 'emailjs-com'

import recentWork from './recentWork'
import RecentWorkCard from '../../Components/RecentWorkCard'

import CodingTitles from '../../Components/CodingTitles'
import settings1 from '../../Images/settings-1.png'
import mobileSettingsLime from '../../Images/mobileSettings-white.png'
import settings2 from '../../Images/settings-2-lime.png'
import desktopSettingsLime from '../../Images/desktopSettings-white.png'
import Footer from '../../Components/Footer'

const Home = () => {
    const [contactForm, setContactForm] = useState({
        name: '',
        email: '',
        message: '',
    })
    const [formLoading, setFormLoading] = useState(false)
    const [contactFormSuccess, setContactFormSuccess] = useState(null)


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    const onContactFormChange = (e) => {
        setContactForm({ ...contactForm, [e.target.name]: e.target.value })

        if(contactFormSuccess !== null) {
            setContactFormSuccess(null)
        }
    }





    const submitContactForm = (e) => {
        e.preventDefault()
        setFormLoading(true)
        emailjs.sendForm(
            'service_mmcz72s',
            'template_8mosbti',
            e.target,
            'user_OtV88kiVLLcYQUuIO55Ef'
        ).then(response => {
            // console.log(response)
            setContactForm({ ...setContactForm,
                name: '',
                email: '',
                message: '',
             })
            setFormLoading(false)
            setContactFormSuccess(true)

        }).catch(error => {
            // console.log(error)
            setFormLoading(false)
            setContactFormSuccess(false)
        })
    }



    return (
        <div className='pages home'>
            {/* <div className='scroll' > */}
            <section className='sections noBackgroundColor hasImg hero'>
                <div className='containers one'>
                    <h1 className='h1'>
                        Hey, <br></br>I'm Da'Kirah,<br></br>
                        <span className='colorChangeText1 onlyUnder414'>Frontend <br></br></span><span className='colorChangeText2 onlyUnder414'>Developer</span><span className='colorChangeText notUnder414'>Frontend Developer</span>
                    </h1>

                    <p className='marginBottom0'>Passionate Frontend Developer with 7 years of experience leading and developing web and mobile apps for nonprofits and individuals.
</p>
                </div>

                <div className='containers two'>
                    <div className='grid'>
                        <img src={settings1} className='rotate heroImages' alt='' />
                        <img src={mobileSettingsLime} className='heroImages' alt='' />
                        <img src={desktopSettingsLime} className='heroImages' alt='' />
                        <img src={settings2} className='rotate heroImages' alt='' />
                    </div>

                </div>
            </section>

            <section className='sections languages'>
                <div className='containers'>
                    <h2 className='h2'>Languages</h2>

                    <div className='width100Percent codingTitlesContainer'>
                        <CodingTitles
                            title='HTML5' color='colorOrange1'
                        />
                        <CodingTitles
                            title='CSS3' color='colorBlue1'
                        />
                        <CodingTitles
                            title='JS' color='colorYellow'
                        />
                        <CodingTitles
                            title='React' color='colorBlue0'
                        />
                        <CodingTitles
                            title='React Native' color='colorBlue0'
                        />
                        <CodingTitles
                            title='Swift' color='colorOrange0'
                        />
                    </div>
                </div>

            </section>

            <section className='sections noBackgroundColor recentWork'>
                <div className='containers backgroundColor one'>
                    <h2 className='h2'>Recent work</h2>
                    <div className='grid'>
                        {recentWork && recentWork.map((work) => {
                            return (
                                <RecentWorkCard key={work.title}
                                    to={work.to}
                                    title={work.title}
                                    type={work.type}
                                    image={work.image}
                                />
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className='sections contact'>
                <div className='containers alignCenter'>
                    <h2 className='h2'>Let's connect!</h2>

                    <form onSubmit={submitContactForm} className='flexColumn alignCenter'>
                        <div className='formRow1'>
                            <input
                                type='text'
                                name='name'
                                value={contactForm.name}
                                placeholder='Name'
                                className='inputs notTextArea'
                                onChange={onContactFormChange}
                                required
                            />
                            <input
                                type='email'
                                name='email'
                                value={contactForm.email}
                                placeholder='Email'
                                className='inputs notTextArea'
                                onChange={onContactFormChange}
                                required
                            />
                        </div>
                        <textarea
                            name='message'
                            value={contactForm.message}
                            placeholder='Message'
                            className='inputs textArea'
                            onChange={onContactFormChange}
                            rows={8}
                            required
                        />

                        {formLoading === false ?
                            <button type='submit' className='btns colorWhite submitBtn'>Send!</button>
                            :
                            <img src={settings1} className='rotate fast' alt='sending...' style={{ width: '2.6em', height: '2.6em' }} />
                        }
                    </form>

                    <div className='textAlignCenter marginAuto fontSize07em'>
                        {contactFormSuccess === true &&
                            <p className='colorLime marginTop20'>Successfully sent!</p>
                        }

                        {contactFormSuccess === false &&
                            <p className='colorRed marginTop20'>There was a problem. Please try again later.</p>
                        }
                    </div>
                </div>
            </section>

            <Footer />
            {/* </div> */}
        </div>
    )
}


export default Home
