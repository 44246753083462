import React from 'react'
// import { Link } from 'react-router-dom'

const Navbar = (props) => {
    
    const logoText = '<DK />'

    return (
        <div id='navbar' className={'backgroundColorBlack0'}>
            <div className='containers'>
                <button onClick={() => window.scrollTo(0,0)} className='flexColumnCenter logoText'>{logoText}</button>
                <div>
                </div>
            </div>
        </div>
    )
}


export default Navbar