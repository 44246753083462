import React from 'react'
import { Link } from 'react-router-dom'


const Button = (props) => {
    let hasUnderline = ''
    if (props.underline) {
        hasUnderline = 'underline'
    }

    if (props.external) { // External Button
        return (
            <a href={props.to} className={props.social ? `btns noStyle ${props.color} social` : `btns noStyle ${props.color} ${hasUnderline}`} rel='noopener noreferrer' target='_blank'>{props.text}</a>
    )
    } else { // Internal button
        return (
            <Link to={props.to} className={`btns ${props.color}`}>{props.text}</Link>
        )
    }
}

export default Button