import React from 'react'


const CodingTitles = (props) => {

    return (
        <div className='flexColumn justifyBetween codingTitles'>
            <p>{props.title}</p>
        </div>
    )
}

export default CodingTitles