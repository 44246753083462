import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../Components/Button'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

const Footer = () => {

    const logoText = '<DK />'

    return (
        <div id='footer'>
            <section className='sections'>
                <div className='containers'>
                    <Link to='/' className='flexColumnCenter logoText marginBottom20'>{logoText}</Link>

                    <div className='flexRow justifyBetween marginBottom5'>
                        <Button
                            external
                            social
                            to='https://www.linkedin.com/in/dakirahjenkins/'
                            text={<LinkedInIcon />}
                            color='colorWhite'
                        />
                        <Button
                            external
                            social
                            to='https://github.com/CodingMissDaisy'
                            text={<GitHubIcon />}
                            color='colorWhite'
                        />
                    </div>

                    <p className='copyRight'>Da'Kirah Jenkins © 2022</p>
                </div>
            </section>
        </div>
    )
}


export default Footer